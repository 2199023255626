.react-calendar {
  width: 600px !important;
  height: max-content !important;
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  user-select: none;
}

.react-calendar__tile {
  height: 100px;
}

.react-calendar__tile:hover {
  background-color: #f4b3b3 !important;
  color: white;
  font-weight: 600;
}

.booking {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.booking-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  width: 95%;
  gap: 20px;
}

.booking-calendar-view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  width: 75%;
}

.booking-calendar-dates {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 95%;
}

.booking-calendar-dates-top {
  display: flex;
  align-items: start;
  flex-direction: column;
  min-width: 250px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
}

.booking-calendar-dates-bottom {
  margin-top: 2rem;
}

.booking-calendar-dates-bottom h3 span {
  color: #f4b3b3;
}

.booking-calendar-dates-top p {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 500;
  color: #f4b3b3;
}

.booking-calendar-dates-top span {
  font-size: 1.1rem;
  font-weight: 600;
  opacity: 0.8;
  color: black;
}

.booking-calendar-dates-bottom {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  height: 90%;
  width: 90%;
  flex-wrap: wrap;
}
booking-calendar-dates .booking-calendar-dates h3 {
  color: black;
  font-size: 2rem;
  user-select: none;
}

.booking-calendar-dates ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  overflow: hidden;
  margin-top: 5%;
  width: 100%;
  height: 400px;
  gap: 10px;
  user-select: none;
}

.booking-calendar-search-text {
  color: #495e57;
}

.booking-calendar-dates-li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.booking-calendar-dates-li li {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4b3b3;
  padding: 12px 16px;
  border-radius: 10px;
  width: 50px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-align: center;
  border: 1px solid transparent;
}

.booking-calendar-dates-li li:hover {
  background-color: white;
  color: #f4b3b3;
  border: 1px solid #f4b3b3;
}

.booking-calendar-dates-li li:active {
  scale: 0.9;
}

@media (max-width: 1250px) {
  .react-calendar {
    width: 500px !important;
  }

  .booking-calendar-dates ul {
    height: 550px;
  }

  .booking-calendar-dates {
    height: 90%;
  }

  .react-calendar__tile {
    height: 80px;
  }

  .booking-calendar-dates-li li {
    width: 40px;
    font-size: 0.8rem;
  }
}

@media (max-width: 1000px) {
  .react-calendar {
    width: 450px !important;
  }

  .booking-calendar-dates-li li {
    width: 35px;
    font-size: 0.8rem;
  }

  .booking-calendar-dates-top p {
    font-size: 1.1rem;
  }

  .booking-calendar-dates-top span {
    font-size: 0.9rem;
  }
}

@media (max-width: 850px) {
  .booking {
    width: 100vw;
    height: 100%;
    overflow: hidden;
  }

  .booking-calendar {
    flex-direction: column;
    gap: 60px;
  }

  .booking-calendar-dates {
    margin-bottom: 10%;
    width: 87%;
  }

  .booking-calendar-dates ul {
    flex-direction: row;
    margin-top: 5%;
    height: initial;
  }
  .react-calendar {
    width: 600px !important;
  }

  .booking-calendar-view {
    margin-top: 5%;
  }

  .booking-calendar-dates-bottom h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
}

@media (max-width: 680px) {
  .react-calendar {
    width: 500px !important;
  }

  .booking-calendar-dates {
    width: 90%;
  }

  .booking-calendar-dates-li li {
    width: 50px;
    font-size: 0.8rem;
  }

  .react-calendar__tile {
    height: 70px;
  }
}

@media (max-width: 500px) {
  .react-calendar__tile {
    height: 60px;
  }

  .booking-calendar-dates ul {
    justify-content: space-around;
  }

  .booking-calendar-dates-top {
    min-width: 200px;
  }
}
