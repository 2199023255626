* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.link {
  list-style: none;
  color: inherit;
  text-decoration: none;
}

.no-select {
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For WebKit-based browsers */
  -ms-user-select: none; /* For older versions of Internet Explorer */
  -moz-user-select: none; /* For Firefox */
  -webkit-tap-highlight-color: transparent; /* Prevent tap highlight effect on mobile */
}
