.bookingConfirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  overflow: hidden;
  color: #45474b;
}

.bookingConfirmWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bookingConfirm h1 {
  font-size: 3rem;
  margin: 1rem 0;
  text-align: center;
  color: #498672;
}

.bookingConfirm p {
  font-size: 1.5rem;
  margin: 1rem 0;
  text-align: center;
}

.bookingConfirm span {
  font-weight: 700;
  color: #495e57;
}

.bookingConfirm__svg {
  width: 200px;
  height: 200px;
  border: none;
}

.bookingConfirm-back {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingConfirm-back:hover {
  background-color: #e0e0e0;
}

.bookingConfirm-calendar {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #5bac7e;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%;
}

.bookingConfirm-calendar:hover {
  background-color: #498672;
}

.bookingConfirm-calendar:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .bookingConfirm {
    margin: 1rem;
  }

  .bookingConfirm h1 {
    font-size: 2rem;
  }

  .bookingConfirm p {
    font-size: 1.2rem;
  }

  .bookingConfirm__svg {
    width: 150px;
    height: 150px;
  }

  .bookingConfirm-back {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
  }

  .bookingConfirm-calendar {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
  }
}
