.about {
  width: 100vw;
  height: min-content;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.about-wrapper-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8rem 12rem;
  gap: 6rem;
}

.about-wrapper-main h1 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
}

.about-wrapper {
  background-color: #4b4040;
  padding: 2rem 6rem;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}

.about-wrapper-top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.about-image {
  width: 100%;
  height: 750px;
  object-fit: cover;
  background-color: #f4b3b3;
}

.about-text {
  color: white;
  display: flex;
  height: 90%;
  width: 150%;
  font-size: 1.25rem;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.about-text h2 {
  font-size: 3rem;
  font-family: "Over the Rainbow", cursive;
  color: #f4b3b3;
  font-weight: 400;
}

@media (max-width: 1500px) {
  .about-text h2 {
    font-size: 2rem;
  }

  .about-text {
    font-size: 1.1rem;
  }

  .about-image {
    height: 600px;
    width: 50%;
  }
}

@media (max-width: 1200px) {
  .about-text h2 {
    font-size: 1.7rem;
  }

  .about-wrapper {
    padding: 2rem;
    gap: 2rem;
  }

  .about-text {
    font-size: 0.9rem;
  }

  .about-image {
    height: 150%;
  }
}

@media (max-width: 1000px) {
  .about-wrapper-main {
    padding: 8rem;
    gap: 2rem;
  }

  .about-image {
    height: 550px;
  }
}

@media (max-width: 768px) {
  .about-wrapper-main h1 {
    font-size: 1.5rem;
  }

  .about-wrapper-main {
    padding: 4rem 25px;
  }
  .about-wrapper {
    padding: 1rem;
    gap: 1rem;
    height: 450px;
  }

  .zoom-container {
    width: 100%;
  }

  .about-image {
    height: 550px;
  }

  .about-text {
    font-size: 0.75rem;
  }

  .about-text h2 {
    font-size: 1.25rem;
  }

  .about-wrapper-top {
    margin-top: 2rem;
  }
}

@media (max-width: 600px) {
  .about-wrapper {
    flex-direction: column;
  }

  .about-image {
    height: 400px;
    width: 100%;
    object-position: 0 0;
  }

  .about-text {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .about-wrapper-main h1 {
    font-size: 1.25rem;
  }

  .about-wrapper-main {
    gap: 2rem;
  }
}
