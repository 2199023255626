.home {
  width: 100%;
  height: calc(100vh - 4rem);
  overflow: hidden;
  position: relative;
  top: 8rem;
  font-family: "Quicksand", serif;
}

.home-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  padding: 2rem 0;
  gap: 6rem;
}

.home-paragraph {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  text-align: center;
  padding: 0 12rem;
}

.home-paragraph-logo {
  font-weight: 500;
}

.home-image-container {
  display: flex;
  justify-content: center;
  position: relative;
}

.home-image-container img {
  width: 100%;
  height: 80%;
  border-top: 2px solid #4b4040;
  border-bottom: 2px solid #4b4040;
}

.home-main-button {
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}

.home-main-button button {
  background-color: white;
  padding: 1.5rem 4.5rem;
  font-size: 1.5rem;
  color: #4b4040;
  letter-spacing: 0.5rem;
  cursor: pointer;
  box-shadow: inset 0 0 0 2px #4b4040;
}

.topBar-appointment-mobile {
  position: fixed;
  width: 100px;
  height: 100px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #4b4040;
  border-radius: 50%;
  bottom: 25px;
  right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #4b4040;
  animation: pulse-animation2 1s infinite;
  flex-direction: column;
}

.topBar-appointment-mobile-wrapper {
  height: 75%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 2px;
  font-size: 0.85rem;
}

.topBar-appointment-mobile-icon {
  animation: scaleAnimation 1s infinite;
  width: 2rem !important;
  height: 2rem !important;
}

@keyframes pulse-animation2 {
  0% {
    box-shadow: 0 0 0px 0px #4b404068;
  }
  100% {
    box-shadow: 0 0 0px 4px #4b4040;
  }
}
@keyframes scaleAnimation {
  0%,
  100% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
}

@media screen and (max-width: 1000px) {
  .home-paragraph {
    padding: 0 4rem;
  }

  .home-main-button button {
    padding: 1.25rem 3.75rem;
  }
}

@media screen and (max-width: 768px) {
  .home-paragraph {
    font-size: 0.8rem;
    padding: 0 2rem;
    letter-spacing: 0.2rem;
  }

  .home-main {
    padding: 0;
  }

  .home-main-button button {
    padding: 1rem 2rem;
    font-size: 1rem;
  }

  .home-image-container img {
    height: 200%;
  }

  .marquee-container {
    font-size: 1rem !important;
    height: 30px !important;
  }
}
