.contact {
  background: #4b4040;
  overflow: hidden;
  padding: 8rem 12rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  color: white;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10%;
}

.contact-top {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contact-bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.contact-button {
  padding: 10px 20px;
  border: none;
  background: white;
  color: #0f0000;
  font-size: 1rem;
  font-weight: 500;
  width: 35%;
  cursor: pointer;
  border: 1px solid transparent;
  text-align: center;
}

.contact-button:hover {
  background: #4b4040;
  color: white;
  border: 1px solid white;
}

.contact-button:active {
  transform: scale(0.98);
}

.contact-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 30px;
  flex: 3;
  justify-content: start;
}

.contact-left img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  align-self: center;
}

.contact-left h3 {
  font-size: 2rem;
  letter-spacing: 0.5rem;
  margin-left: 1rem;
  font-weight: 200;
}

.contact-left p {
  letter-spacing: 1px;
  opacity: 0.8;
  font-family: "Over the Rainbow", cursive;
  text-align: center;
}

.contact-left-icons {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  width: 35%;
}

.contact-left-icons a {
  cursor: pointer;
}

.contact-right {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  flex: 5;
  gap: 5%;
}

.contact-right-2-whatsapp {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.contact-right-2-location {
  display: flex;
  gap: 10px;
  align-items: start;
  flex-direction: column;
}

.contact-right-2-location-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.contact-right-2-mail {
  display: flex;
  gap: 10px;
  align-items: center;
}
.contact-right h3 {
  font-size: 2rem;
  letter-spacing: 2px;
}

.contact-right h4 {
  font-size: 1.1rem;
  letter-spacing: 2px;
  opacity: 0.9;
}

.contact-right p {
  letter-spacing: 1px;
  opacity: 0.6;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.contact-right span {
  letter-spacing: 1px;
  opacity: 0.6;
}

.contact-right-1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-right-2 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.contact-icon {
  color: white;
}

.contact-icon-location {
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .contact-left h3 {
    font-size: 1.75rem;
  }

  .contact-left p {
    letter-spacing: 1px;
    font-size: 0.9rem;
  }

  .contact-right h3 {
    font-size: 1.75rem;
  }
}

@media (max-width: 1000px) {
  .contact {
    padding: 8rem 4rem;
  }
}

@media (max-width: 768px) {
  .contact {
    padding: 4rem 25px;
  }

  .contact-top {
    flex-direction: column;
  }

  .contact-left {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .contact-left h3 {
    font-size: 1.5rem;
    text-align: center;
  }

  .contact-left p {
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }

  .contact-right h3 {
    font-size: 1.5rem;
  }

  .contact-right h4 {
    font-size: 1rem;
  }

  .contact-right p {
    font-size: 0.8rem;
  }

  .contact-right span {
    font-size: 0.8rem;
  }
}

@media (max-width: 568px) {
  .contact-left {
    gap: 5px;
    margin-bottom: 5%;
  }

  .contact-left-icons {
    width: 50%;
  }

  .contact-left h3 {
    font-size: 1.25rem;
  }

  .contact-left p {
    font-size: 0.7rem;
  }

  .contact-right h3 {
    font-size: 1.25rem;
  }

  .contact-right h4 {
    font-size: 0.9rem;
  }
}

@media (max-width: 388px) {
  .contact-left h3 {
    font-size: 1rem;
  }

  .contact-left p {
    font-size: 0.6rem;
  }

  .contact-right h3 {
    font-size: 1rem;
  }

  .contact-right h4 {
    font-size: 0.7rem;
  }
}

@media ((max-width: 388px) and (max-height: 688px)) {
}
