.topBarMinified {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 50px;
  height: 8vh;
  color: black;
  z-index: 1;
  width: calc(100% - 100px);
  margin-bottom: 2rem;
}

.topBarMinified ul {
  margin-right: 16px;
}

.topBarMinified-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.topBarMinified-logo {
  display: flex;
  flex: 1;
  align-items: center;
  opacity: 1 !important;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 80px;
  font-size: 1rem;
  letter-spacing: 0.5rem;
  font-weight: 200;
}

.topBarMinified-logo:hover {
  scale: 1.025;
}

@media screen and (max-width: 768px) {
  .topBarMinified {
    padding: 1rem;
    width: calc(100% - 2rem);
  }
}
