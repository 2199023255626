.marquee-container {
  width: 100%;
  overflow: hidden; /* Hide overflowing content */
  white-space: nowrap; /* Prevent text wrapping */
  background-color: #4b4040;
  padding: 10px 0;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.marquee-content {
  display: inline-block;
  color: white;
  letter-spacing: 0.5rem;
}

@keyframes scroll {
  from {
    transform: translateX(50%); /* Start outside the right edge */
  }
  to {
    transform: translateX(-100%); /* Move outside the left edge */
  }
}
