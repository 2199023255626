.languageItem:hover {
  scale: 1.1;
}

.languageItem:active {
  scale: 0.9;
}

.languageItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 5px;
  opacity: 1;
  cursor: pointer;
}

.languageItem.selected {
  font-weight: 600;
  color: black;
  opacity: 1;
}

.languageItem-icon-selected {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
}
