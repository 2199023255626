.bookingService {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 0 0 4rem 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.bookingService-services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 75px 30px;
  margin-top: 100px;
}

.bookingService-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 350px;
  width: 250px;
  background-color: #adc0b315;
  position: relative;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.bookingService-box-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: absolute;
  top: -15%;
}

.bookingService-box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.bookingSerivce-box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5%;
  gap: 15px;
  margin-top: 37%;
}

.bookingSerivce-box-content h2 {
  font-size: 1.3rem;
  color: black;
  font-weight: 400;
  letter-spacing: 2px;
  text-shadow: -2px -2px 0 rgba(255, 255, 255, 0.156),
    2px -2px 0 rgba(255, 255, 255, 0.167), -2px 2px 0 rgba(255, 255, 255, 0.167),
    2px 2px 0 #ffffff25;
  text-align: center;
  min-height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookingService-box-content-desc {
  font-size: 0.7rem !important;
  text-align: center;
  opacity: 0.6;
  min-height: 60px;
}

.bookingService-box-content-price {
  font-size: 1.2rem;
  color: black;
}

.bookingService-box-content-duration {
  font-size: 0.8rem;
  opacity: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.bookingService-box-duration-icon {
  opacity: 0.5;
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none;
}

.bookingService-box-duration-icon:hover {
  opacity: 1;
}

.bookingService-box-duration-icon:active {
  transform: scale(0.9);
}

.bookingService-box-tooltip {
  width: 200px;
  height: 100px;
}

.bookingService-box-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  width: 200px;
  border: none;
  border-radius: 30px;
  background-color: #4b4040;
  color: white;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.2rem;
  letter-spacing: 2.5px;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
}

.bookingService-box-button:hover {
  background-color: white;
  color: #4b4040;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  transition: 0.2s;
}

.bookingService-box-button:active {
  transform: scale(0.9);
}

.bookingService-box-tooltip {
  max-width: 100%;
  height: min-content !important;
  padding: 1rem !important;
  border-radius: 5px !important;
  z-index: 1001;
}

@media (max-width: 900px) {
  .bookingService-box {
    min-height: 300px;
    width: 200px;
  }

  .bookingService-box-img {
    width: 100px;
    height: 100px;
  }

  .bookingSerivce-box-content h2 {
    font-size: 1rem;
    min-height: 40px;
  }

  .bookingSerivce-box-content p {
    font-size: 0.8rem;
  }

  .bookingService-box-button {
    font-size: 1rem;
    width: 90%;
  }

  .bookingService-box-content-desc {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 768px) {
  .bookingService-services {
    gap: 75px 20px;
  }

  .bookingService-services {
    grid-template-columns: repeat(2, 1fr);
  }

  .bookingService-box {
    min-height: 340px;
    width: 250px;
  }

  .bookingService-box-img {
    width: 130px;
    height: 130px;
  }

  .bookingService-box-content-desc {
    min-height: 40px;
  }

  .bookingService-box-content h2 {
    font-size: 1.5rem;
  }

  .bookingSerivce-box-content p {
    font-size: 1rem;
  }

  .bookingService-box-button {
    font-size: 1.2rem;
  }
}

@media (max-width: 568px) {
  .bookingService-services {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    margin-top: 20px;
  }

  .bookingService-box {
    min-height: 275px;
    width: 75vw;
  }

  .bookingService-box-img {
    top: 40%;
    transform: translate(0, -50%);
    right: 2%;
    width: 110px;
    height: 110px;
  }

  .bookingSerivce-box-content {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-around;
    width: 80%;
  }

  .bookingService-box-button {
    height: 19%;
  }

  .bookingService-box-content-desc {
    text-align: left;
    width: 70%;
    font-size: 0.75rem !important;
    min-height: 62px;
  }
}

@media (max-width: 450px) {
  .bookingService-box {
    width: 85vw;
  }
}
