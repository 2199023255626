/* ErrorBoundary.css */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(92vh - 50px);
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.error-container h1 {
  font-size: 3em;
  color: #d9534f;
  margin-bottom: 0.5em;
}

.error-container p {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.error-container button {
  background-color: #0275d8;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.error-container button:hover {
  background-color: #025aa5;
}

.error-container span {
  font-weight: 600;
}

.error-icon {
  width: 100px !important ;
  height: 100px !important;
  color: #d9534f;
  margin-bottom: 0.5em;
}
