/* Container styles */
.example-container {
  display: grid;
  min-height: 200px;
  place-content: center;
  background-color: #0f172a; /* slate-900 */
  padding: 1rem; /* 4px */
}

/* Button styles */
.draw-outline-button {
  position: relative;
  padding: 0.5rem 1rem; /* 4px 8px */
  font-weight: 500; /* medium */
  color: #f1f5f9; /* slate-100 */
  background: none;
  border: none;
  cursor: pointer;
  transition: color 400ms;
}

.draw-outline-button:hover {
  color: #f4b3b3; /* indigo-300 */
}

/* Outline animation styles */
.draw-outline-button span {
  position: relative;
}

/* Top line */
.draw-outline-button .top-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #f4b3b3; /* indigo-300 */
  transition: width 100ms;
}

.draw-outline-button:hover .top-line {
  width: 100%;
}

/* Right line */
.draw-outline-button .right-line {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 0;
  background-color: #f4b3b3; /* indigo-300 */
  transition: height 100ms 100ms; /* Add delay */
}

.draw-outline-button:hover .right-line {
  height: 100%;
}

/* Bottom line */
.draw-outline-button .bottom-line {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #f4b3b3; /* indigo-300 */
  transition: width 100ms 200ms; /* Add delay */
}

.draw-outline-button:hover .bottom-line {
  width: 100%;
}

/* Left line */
.draw-outline-button .left-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #f4b3b3; /* indigo-300 */
  transition: height 100ms 300ms; /* Add delay */
}

.draw-outline-button:hover .left-line {
  height: 100%;
}
