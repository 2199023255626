.languageDropdown-item-language-icon-div {
  position: relative;
}

.languageDropdown-item-language-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.languageDropdown-item-language-icon svg {
  cursor: pointer;
  padding: 10px;
  -webkit-tap-highlight-color: transparent;
}

.languageDropdown-item-language-icon p {
  text-align: center;
}

.languageDropdown-item-languages-web {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  letter-spacing: 1.4px;
  user-select: none;
  align-items: flex-end;
  padding: 10px 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.468);
  color: black;
  border-radius: 20px;
  gap: 10px;
  width: 100%;
  font-size: 14px;
}

.languageDropdown-item-languages-web img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 25px;
  height: 25px;
  letter-spacing: 1.4px;
  user-select: none;
  flex-direction: column;
}
