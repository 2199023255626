.zoom-container {
  overflow: hidden; /* Ensure that the zoomed image does not overflow the container */
  position: relative; /* Create a positioned context */
}

.zoom-image {
  width: 100%; /* Ensure the image fits within the container */
  height: 100%;
  transform-origin: center; /* Set the origin of the transformation */
}
