.ratings {
  height: 30vh;
  width: 100vw;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.ratings-wrapper {
  height: 100%;
}

.ratingsWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  height: 100%;
  width: 100vw;
  background-color: rgba(15, 0, 0, 0.58);
}

.ratings img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
  opacity: 0.75;
  position: absolute;
}

.ratings-h1 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
  color: white;
  text-align: center;
}

.ratings-card-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 15%;
}

.ratings-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

@media (max-width: 1200px) {
  .ratings-h1 {
    font-size: 2.5rem;
  }

  .ratings-card-wrapper {
    gap: 10%;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .ratings {
    justify-content: space-evenly;
  }

  .ratings-h1 {
    font-size: 1.5rem;
  }

  .ratings-card-wrapper {
    gap: 8%;
    font-size: 0.7rem;
  }

  .ratings-card {
    text-align: center;
  }
}

@media (max-width: 568px) {
  .ratings {
    justify-content: space-evenly;
  }

  .ratings-h1 {
    font-size: 0.9rem;
  }

  .ratings-card-wrapper {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .ratings-card-wrapper p {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  .ratings-card {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.25rem;
  }
}
