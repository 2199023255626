.bookingDetail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.bookingDetail form {
  width: 80%;
  height: 100%;
  margin: 3rem 0;
}

@media (max-width: 800px) {
  .bookingDetail-error > div {
    margin-top: 15px;
  }
}
