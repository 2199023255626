.carouselComponent {
  min-height: 500px;
  overflow: hidden;
}

.carouselComponent-top {
  padding: 2rem 12rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.carouselComponent-top h1 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
}

.carouselComponent-top img {
  position: relative;
  bottom: 4rem;
  width: 200px;
  height: 200px;
}

.slick-center {
  transform: scale(1.1);
  overflow: visible;
  height: 400px;
  transition: transform 0.5s;
}

.center {
  opacity: 0.8;
  transition: 0.3s;
  margin-top: 40px;
}

.carousel-slide {
  width: 350px;
  height: 350px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel-background {
  height: 300px;
  width: max-content;
  position: absolute;
  z-index: 1;
  width: 90%;
}

.carousel-background span {
  font-size: 1.5rem;
  color: white;
  letter-spacing: 0.5rem;
  margin-bottom: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
}

.slick-slider *:focus {
  outline: none;
}

.slick-prev {
  left: 25px;
  z-index: 10;
  top: 45%;
  transform: translate(0, -45%);
}

.slick-next {
  right: 25px;
  z-index: 10;
  top: 45%;
  transform: translate(0, -45%);
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px;
}

.carousel-slide img {
  width: 350px;
  height: 300px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

@media (max-width: 1000px) {
  .carouselComponent-top {
    padding: 2rem 8rem;
  }
}

@media (max-width: 768px) {
  .carouselComponent-top {
    padding: 2rem 25px 0;
  }

  .carouselComponent-top h1 {
    font-size: 1.5rem;
  }

  .carouselComponent-top img {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 400px) {
  .carouselComponent-top h1 {
    font-size: 1.25rem;
  }
  .carouselComponent-top img {
    width: 100px;
    height: 100px;
  }
}
