.topBar {
  height: 4rem;
  display: flex;
  padding: 2rem 12rem;
  position: fixed;
  top: 0;
  width: calc(100% - 24rem);
  z-index: 10;
}

.topBar-shadow {
  background: linear-gradient(#4b4040, #d0cfd100);
}

.topBar-menu-light {
  color: white !important;
}

.topBar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
}

.topBar-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  flex: 7;
  color: #0f0000;
  -webkit-tap-highlight-color: transparent;
}

.topBar-logo {
  flex: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 200;
  height: 40px;
  font-size: 2rem;
  letter-spacing: 1rem;
  user-select: none;
  cursor: pointer;
}

.topBar-item-last {
  display: flex;
  justify-content: space-around;
  letter-spacing: 1.4px;
  user-select: none;
  align-items: center;
}

.topBar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
  width: 80px;
  height: 40px;
  letter-spacing: 1.4px;
  user-select: none;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.75;
}

.topBar-item:hover {
  scale: 1.1;
}

/* mobile responsivness */

.menu-button {
  display: none;
}

.topBar-menu {
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 1300px) {
  .topBar-item {
    width: 60px;
    font-size: 14px;
  }

  .topBar-item-last {
    gap: 20px;
  }
}

@media (max-width: 1200px) {
  .topBar-menu {
    justify-content: space-evenly;
  }

  .topBar-item {
    width: 48px;
    letter-spacing: 1.2px;
    font-size: 12px;
  }
}

@media (max-width: 1000px) {
  .topBar {
    padding: 2rem 4rem;
    width: calc(100% - 8rem);
  }
  .topBar-item {
    width: 38px;
    font-size: 10px;
  }
}

@media (max-width: 900px) {
  .topBar-item {
    width: 32px;
  }
}

@media (max-width: 768px) {
  .menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .menu-button svg {
    padding: 10px;
  }

  .topBar-logo {
    justify-content: start;
    font-size: 1.5rem;
    letter-spacing: 0.5rem;
  }

  .menu-button-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 1;
    flex: 11.5;
    margin-right: calc(2rem - 10px);
  }

  .menu-button-wrapper-open {
    color: white;
  }

  .menu-button-wrapper-light {
    color: white;
  }

  .topBar-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 200px;
    background: #5452527a;
    padding: 20px 0 0;
    box-sizing: border-box;
    overflow: auto;
    flex-direction: column;
    transform: translateX(100%);
    padding-top: 15%;
    overflow: hidden;
    color: white;
  }

  .topBar-menu li:first-child {
    border-top: none;
  }

  .topBar-appointment {
    padding: 10px;
    font-size: 1.2rem;
    border: none;
    height: 100%;
    color: white;
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  }

  .topBar-item {
    width: 100%;
    font-size: 18px;
    border-bottom: #ffffff95 1px solid;
    height: 100%;
    gap: 10px;
    flex-direction: row;
    justify-content: start;
    padding-left: 20%;
  }

  .topBar-item:first-child {
    border-top: #ffffff95 1px solid;
  }

  .topBar-item:last-child {
    border-bottom: none;
    background-color: #4b4040;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: start;
  }

  .topBar-menu.open {
    transform: translateX(0);
  }

  .topBar {
    padding: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }

  .topBar-item-last {
    width: 100%;
    font-weight: 1.5rem;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0px 0px #f4cf1481, inset 0 0 0px 0px #f4cf1481;
    border: 2px solid #f4cf1481;
  }
  100% {
    border: 2px solid #f4cf14;

    box-shadow: 0 0 0px 2px #f4cf14, inset 0 0 1px 2px #f4cf14;
  }
}
