.services-top {
  margin-top: 4rem;
  padding: 2rem 12rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.services-top h1 {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0.5rem;
}

.services-top img {
  position: relative;
  bottom: 4rem;
  width: 200px;
  height: 200px;
}

.outer-container {
  overflow-x: hidden;
}

.outer-container-mobile {
  overflow-x: initial;
}

.container {
  display: flex;
  height: 100vh;
  width: 300%;
}

.container-mobile {
  height: 100%;
  flex-direction: column;
  width: 100%;
}

.panel {
  flex: 0 0 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: 0.4rem;
  background-color: #4b4040;
}

.panel-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8rem 12rem;
  position: relative;
  height: 50%;
  gap: 4rem;
}

.panel img {
  height: 500px;
  flex: 2;
  object-fit: cover;
  user-select: none;
  -webkit-user-drag: none;
}

.panel p {
  flex: 3;
  text-align: center;
  color: white;
}

.panel button {
  padding: 1.5rem 2rem;
  font-size: 1.25rem;
  letter-spacing: 0.5rem;
  width: 400px;
  background-color: white;
  border: none;
  color: #4b4040;
  position: absolute;
  left: 25%;
  bottom: 25%;
  border: 2px solid #f4b3b3;
}

@media (max-width: 1400px) {
  .panel-wrapper {
    padding: 2rem 8rem;
  }

  .panel button {
    bottom: 10%;
  }
}

@media (max-width: 1200px) {
  .panel-wrapper {
    padding: 4rem;
  }
}

@media (max-width: 1000px) {
  .services-top {
    padding: 2rem 8rem;
  }
}

@media (max-width: 1000px) {
  .services-top {
    padding: 2rem 8rem;
  }

  .panel button {
    width: 300px;
  }

  .panel p {
    font-size: 1.25rem;
  }
}

@media (max-width: 850px) {
  .panel button {
    width: 250px;
    padding: 1rem;
  }

  .panel p {
    font-size: 1.15rem;
  }
}

@media (max-width: 768px) {
  .services-top {
    padding: 2rem 25px;
  }

  .panel p {
    flex: 4;
    letter-spacing: 0.2rem;
  }

  .panel img {
    height: 100%;
  }

  .services-top h1 {
    font-size: 1.5rem;
  }

  .services-top img {
    width: 150px;
    height: 150px;
  }
  .panel-wrapper {
    flex-direction: column;
    height: 80%;
    padding: 2rem;
    gap: 0;
  }

  .panel-wrapper img {
    width: 100%;
  }

  .panel p {
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel button {
    width: 400px;
    padding: 2rem;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

@media (max-width: 568px) {
  .panel p {
    width: 100%;
  }

  .panel button {
    width: 300px;
    padding: 1rem;
  }

  .panel-wrapper img {
    height: 300px;
  }
}

@media (max-width: 400px) {
  .services-top img {
    width: 100px;
    height: 100px;
  }

  .services-top h1 {
    font-size: 1.25rem;
  }
}

@media (max-width: 358px) {
  .panel p {
    font-size: 0.9rem;
  }

  .panel button {
    width: 250px;
    padding: 1rem;
  }

  .panel-wrapper img {
    height: 300px;
  }
}

@media ((max-width: 768px) and (max-height: 768px)) {
  .panel-wrapper p {
    font-size: 1.25rem;
  }

  .panel button {
    padding: 0.75rem;
    width: 250px;
  }
}
